/* ContactForm.css */
.contact-form-modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 100px;
    box-sizing: border-box;
  }
  
  .contact-form-modal .form-container {
    background-color: #fff;
    margin: auto;
    padding: 20px;
    width: 60%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .contact-form-modal input,
  .contact-form-modal textarea,
  .contact-form-modal select {
    background-color: #F5F5F2;
    width:49%;
  }
  
  .contact-form-modal textarea {
    width: 100%;
    box-sizing: border-box;
  }
  
  .contact-form-modal .close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .contact-form-modal .close:hover,
  .contact-form-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .contact-form-modal .form-row {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  
  .contact-form-modal .form-column {
    flex: 1;
    margin-right: 15px;
  }
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
