@import url("https://fonts.googleapis.com/css2?family=Exo:wght@100;200;300;400;500;600;700;800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400&family=Poppins:wght@100;200;400;500;600;700&display=swap");
body {
  background-color: #000;
}
p {
  color: #fff;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.btnQubiqon {
  color: #ffffff;
  border-radius: 40px!important;
  border: none!important;
  background: linear-gradient(90deg, #00ffad 5%, #7c41b0 95%);
  width: 160px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  cursor: pointer; /* Add cursor style for better user experience */
}

.btnQubiqon, .btnQubiqon * {
  display: inline; /* Set the display property to inline for all children */
  vertical-align: middle; /* Ensure proper vertical alignment */
}

.btnQubiqon svg {
  height: 80%; /* Adjust the percentage as needed */
  width: auto;
  margin-left: 10px;
}




.btnWhite {
  border-radius: 40px!important;
  background: #fff!important;
  color: #000;
  width: 160px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  cursor: pointer; /* Add cursor style for better user experience */  
  position: relative;
}

.btnWhite, .btnWhite * {
  display: inline; /* Set the display property to inline for all children */
  vertical-align: middle; /* Ensure proper vertical alignment */
}
.btnWhite svg {
  height: 80%; /* Adjust the percentage as needed */
  width: auto;
  margin-left: 10px;
}

.navbg {
  background: repeating-linear-gradient(
    to botton,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 64px,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 64px
  );
  /*backdrop-filter: blur(50px);*/
}
.bannerBkg {
  /* background: repeating-linear-gradient(
  270deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 103.91%,
  rgba(0, 0, 0, 0.2),
  rgba(0, 0, 0, 0.2) 10px,
  rgba(0, 0, 0, 0.3) 10px,
  rgba(0, 0, 0, 0.3) 20px
 ),
url(../assets/images/bannerrightimage.png); */
  background: repeating-linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 64px,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 64px,
    #f6ba52 10px,
    #ffd180 10px,
    #ffd180 20px
  );
  height: 80vh;
  min-height: 500px;
}
.masthead {
  height: 100vh;
  min-height: 500px;
  /* background-color: #000; */
  /* background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080'); */
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
}
.leftbnr {
  background: rgb(0, 222, 151);
  background: radial-gradient(
    circle,
    rgba(0, 222, 151, 1) 0%,
    rgba(124, 65, 176, 0) 63%
  );
  position: absolute;
  width: 50%;
  height: 80%;
  margin-top:auto;
  margin-bottom: auto;
  left: 10%;
  /* z-index: 1; */
}
.rightbnr {
  background: rgb(124, 65, 176);
  background: radial-gradient(
    circle,
    rgba(124, 65, 176, 1) 0%,
    rgba(124, 65, 176, 0) 63%
  );
  position: absolute;
  width: 50%;
  height: 80%;
  margin-top:auto;
  margin-bottom: auto;
  right: 10%;
  /* z-index: 1; */
}
.leftbnrbtm {
  background: rgb(0, 222, 151);
  background: radial-gradient(
    circle,
    rgba(0, 222, 151, 1) 0%,
    rgba(124, 65, 176, 0) 63%
  );
  position: absolute;
  width: 50%;
  height: 100%;
  bottom: 5;
  right: 5%;
  /* z-index: 1; */
}
.rightbnrbtm {
  background: rgb(124, 65, 176);
  background: radial-gradient(
    circle,
    rgba(124, 65, 176, 0.8) 0%,
    rgba(124, 65, 176, 0) 63%
  );
  position: absolute;
  width: 80%;
  height: 100%;
  top: 0;
  left: 10%;
  /* z-index: 1; */
}
.DialogBG {
  position: absolute;
  width: 80%;
  height: 100%;
  top: 0;
  left: 10%;
}
.bnr_bkg_img {
  right: 0;
  position: absolute;
  /* z-index: 11; */
  width: 50%;
  height: 100%;
  background-image: url("../assets/images/bannerrightimage.png");
  background-repeat: no-repeat;
}
h2 {
  font-family: "Exo", sans-serif;
  font-family: "Nunito Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}
.bannerh2 {
  color: #fff;
  font-family: "Exo", sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: 101.5px; /* 105.729% */
  letter-spacing: 2.88px;
  position: relative;
}
.text_h4 {
  background: linear-gradient(
    91deg,
    #10e6ae 1.74%,
    #fff 43.71%,
    #fff 61.62%,
    #764bb0 102.48%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Exo 2;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 100% */
  letter-spacing: 2.24px;
}
.text_h5 {
  color: #fff;
  font-family: Nunito Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.line_bkg_border {
  position: absolute;
}
.line_bkg_borderTwo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.cardOne {
  border-radius: 24px;
  background: #00de97;
  backdrop-filter: blur(15px);
  /* padding: 10px; */
  height: 100%;
}
.cardTwo {
  border-radius: 24px;
  background: #151515;

  backdrop-filter: blur(15px);
  height: 100%;
}
.cardThree {
  border-radius: 24px;
  background: #151515;

  backdrop-filter: blur(15px);
  height: 100%;
}
.cardFour {
  border-radius: 24px;
  background: #1b1820;

  backdrop-filter: blur(15px);
  height: 100%;
}
.cardFive {
  border-radius: 24px;
  background: linear-gradient(
    97deg,
    #e6dbff 0%,
    #f6f6f6 44.95%,
    #d8d7ff 101.53%
  );
  height: 100%;
}
.crdImgRight {
  position: absolute;
  /* right: -90px; */
  /* width: 50%; */
  width: -webkit-fill-available;
  height: 100%;
  overflow: hidden;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
.btn_line {
  color: #fff;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  text-decoration-line: underline;
  cursor: pointer;
}
/* .row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
}
.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
} */
.row-flex {
  display: flex;
  flex-wrap: wrap;
}

/* vertical spacing between columns */

.row-flex [class*="col-"] {
  margin-bottom: 30px;
}
.horizonDiv {
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(32px);
}
.ser_top {
  border-radius: 32px;
  border: 0.8px solid #504cff;
  text-align: center;
}
.ser_top p {
  color: #0a0c14;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  padding: 8px;
  text-align: center;
}
.serH5 {
  color: #0a0c14;

  font-family: Nunito Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 32.64px */
}
.msg_crd {
  border-radius: 24px;
  border: 1px solid #fff;

  background: rgba(255, 255, 255, 0.25);

  backdrop-filter: blur(15px);
}
.mgs_crd_other {
  border-radius: 24px;
  border: 1px solid #fff;

  background: rgba(21, 21, 21, 0.4);

  backdrop-filter: blur(15px);
}
.user {
  border-radius: 50px;
  background: url("../assets/images/user.png"), lightgray 50% / cover no-repeat;
}
.msgUsrTitle {
  color: #fff;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
}
.msgUsrAbout {
  color: #fff;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.msgContent {
  color: #fff;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
}
.footr_top {
  border-top: 1px solid #635189;
  border-bottom: 1px solid #635189;
  background: linear-gradient(
    90deg,
    #00de97 10.35%,
    rgba(0, 222, 151, 0.55) 24.09%,
    rgba(0, 222, 151, 0) 58.32%
  );
  padding: 25px 0 25px 0;
}
.text-white {
  color: #fff;
  text-decoration: none;
  position: relative;
}
.ftrbottom_p {
  color: #fff;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-bottom: 0px;
}
.ftrbottom_p ul li {
  color: #fff !important;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-bottom: 0px;
}
footer {
  background: rgba(26, 26, 26, 0.4);
  background: radial-gradient(
    circle,
    rgba(124, 65, 176, 0.8) 0%,
    rgba(124, 65, 176, 0) 63%
  );

  backdrop-filter: blur(10px);
}
@media screen and (min-width: 900px) {
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 900px) {
  .bannerh2 {
    font-size: 70px;
    line-height: 60px;
    letter-spacing: 1px;
  }
  .line_bkg_border {
    display: none;
  }
  .DialogBG {
    display: none;
  }
}
/*@media screen and (min-device-width: 480px) 
    and (max-device-width: 768px) {
       .bannerh2 {
       font-size: 45px;
       line-height: 40px;
       letter-spacing: 0px;
      }
      .line_bkg_border{
        display: none;
      }
      .DialogBG{
        display: none;
      }
    
}*/

/* For Mobile Portrait View */
@media screen and (max-device-width: 768px) and (orientation: portrait) {
  .bannerh2 {
    font-size: 45px;
    line-height: 40px;
    letter-spacing: 0px;
  }
  .line_bkg_border {
    display: none;
  }
  .DialogBG {
    display: none;
  }
  .text_h4 {
    font-size: 35px;
    line-height: 47px; /* 100% */
  }
  .bnr_bkg_img {
    right: 0;
    left: 0;
    width: 100%;
    display: none;
  }
  .navbar-nav,
  .navbar-expand-lg {
    background-color: #000 !important;
  }
  .bannerBkg {
    background: repeating-linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 40px,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 40px
    );
  }

  .navbg {
    background: repeating-linear-gradient(
      to botton,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 10px,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 10px
    );
  }
  .crdImgRight {
    display: none;
  }
  .leftbnr {
    width: 50%;
    height: 50%;
    bottom: 0;
    left: 0%;
    z-index: -1;
  }
  .rightbnr {
    background: rgba(124, 65, 176, 0.5);
    background: radial-gradient(
      circle,
      rgba(124, 65, 176, 1) 0%,
      rgba(124, 65, 176, 0) 63%
    );
    width: 50%;
    height: 50%;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .rightbnrbtm {
    background: rgba(124, 65, 176, 0.8);
    background: radial-gradient(
      circle,
      rgba(124, 65, 176, 0.8) 0%,
      rgba(124, 65, 176, 0) 63%
    );
    width: 100px;
    height: 100px;
    z-index: -1;
    left: 20%;
    top: 10%;
  }
  .leftbnrbtm {
    background: rgb(0, 222, 151, 0.8);
    background: radial-gradient(
      circle,
      rgba(0, 222, 151, 0.8) 0%,
      rgba(124, 65, 176, 0) 63%
    );
    width: 100px;
    height: 100px;
    left: 20%;
    top: 10%;

    z-index: -1;
  }
}
